import { Content, asImageSrc, asLink } from '@prismicio/client';
import { PrismicNextImage } from '@prismicio/next';
import { SliceComponentProps } from '@prismicio/react';
import classNames from 'classnames';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Button, ButtonAppearance, ButtonColor } from 'ui/components/Button';
import { Carousel } from 'ui/components/Carousel';
import HeroLight from 'ui/components/Hero/HeroLight';
import { PrismicRichText } from 'ui/components/PrismicRichText';
import { DesktopBreakpoint, ResponsiveBreakpoint } from 'ui/components/ResponsiveImage';
import { useWindowsSize } from 'ui/hooks/useWindowSize';
import { SVGLibrary } from 'ui/svgs/library';
import Link from 'ui/components/Link';
import ChevronRight from 'ui/svgs/library/ChevronRight';
import { format } from 'date-fns';
import { useRouter } from 'next/router';
import styles from './index.module.scss';
import { normalizeBlogs } from './hero.helper';

const FIXED_AUTHOR_IMG_FILE_NAME = 'ZiaJ5_Pdc1huKrn3_1-13.png';
/**
 * Props for `Hero`.
 */
export type HeroProps = SliceComponentProps<Content.HeroSlice>;

/**
 * Component for "Hero" Slices.
 */
const Hero = ({ slice, index, slices, context }: HeroProps): JSX.Element => {
  const { variation, primary, slice_type, items } = slice;
  const [width] = useWindowsSize();

  if (variation === 'dark') {
    const {
      background_image,
      tablet_background_image,
      mobile_background_image,
      heading,
      description,
      primary_cta_text,
      primary_cta_action,
      primary_cta_url,
      primary_cta_target,
      secondary_cta_text,
      secondary_cta_action,
      secondary_cta_target,
      secondary_cta_url,
      eyebrow_text,
      cta_description,
      text_theme,
      logos,
    } = primary;
    const { title, logos: items } = (logos as any)?.data || {};

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [heroBackground, setHeroBackground] = useState<string>(background_image?.url || '');

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      if (!isNaN(width)) {
        if (width < ResponsiveBreakpoint) {
          setHeroBackground(
            mobile_background_image?.url || tablet_background_image?.url || background_image?.url || '',
          );
        } else if (width < DesktopBreakpoint) {
          setHeroBackground(tablet_background_image?.url || background_image?.url || '');
        } else {
          setHeroBackground(background_image?.url || '');
        }
      }
      const logoSlidercontainer = document.querySelector('#hero-logos-slider-container') as HTMLElement;
      const backdropContainer = document.getElementById('backgrop-logos-hero-dark') as HTMLElement;
      if (logoSlidercontainer) {
        const computeHeightForBackdrop = window.getComputedStyle(logoSlidercontainer, null).getPropertyValue('height');
        if (backdropContainer) {
          backdropContainer.style.height = `${computeHeightForBackdrop}`;
        }
      }
    }, [width, background_image, tablet_background_image, mobile_background_image]);

    const getButtonProps = (action: string, url: any, target: string) => {
      return {
        ...(action === 'Get Started Overlay' ? { onClick: () => null } : { href: asLink(url), target: target }),
      };
    };
    return (
      <section
        className={classNames('wide-container', styles.section, styles.dark)}
        data-slice-type={slice_type}
        data-slice-variation={variation}
        style={{
          backgroundImage: `url("${heroBackground}")`,
          backgroundPositionX: items && items.length > 0 && width < DesktopBreakpoint ? '60%' : 'center',
        }}
        id="hero-dark"
      >
        <div className={classNames(styles.root)}>
          <div className={styles.content}>
            {eyebrow_text && <h2 className={classNames(styles.eyebrow_text, styles[text_theme])}>{eyebrow_text}</h2>}
            <h1 className={classNames(styles.header, styles[text_theme])} data-testid="heading">
              {heading}
            </h1>
            <PrismicRichText
              className={classNames(styles.description, styles[text_theme])}
              field={description}
              applyStyles={true}
              boldHighlight="primary"
            />
            {primary_cta_text && (
              <Button
                className={styles.btn}
                text={primary_cta_text}
                {...getButtonProps(primary_cta_action, primary_cta_url, primary_cta_target)}
                dataTestid="cta-primary"
              />
            )}
            {secondary_cta_text && (
              <Button
                color={ButtonColor.Secondary}
                outline={true}
                className={classNames(styles.btn, styles.secondaryBtn)}
                text={secondary_cta_text}
                {...getButtonProps(secondary_cta_action, secondary_cta_url, secondary_cta_target)}
                dataTestid="cta-secondary"
              />
            )}
            {cta_description && (
              <h2 className={classNames(styles.cta_description, styles[text_theme])}>{cta_description}</h2>
            )}
          </div>
        </div>
        {items && items.length > 0 && (
          <>
            <div id="backgrop-logos-hero-dark" className={classNames(styles.backdropSlider, 'wide-container')}></div>
            <div className={styles.logosContainer} id="hero-logos-slider-container">
              <h4 className={classNames(styles.title, styles[text_theme])} data-testid="heading">
                {title}
              </h4>
              <div className={styles.logosSlider}>
                <Carousel
                  slideSize={256}
                  slideGap="lg"
                  loop={true}
                  autoPlay
                  autoPlayDelay={2000}
                  speed={0.2}
                  withControls={false}
                  withIndicators={false}
                  className={classNames(styles.containerRoot)}
                >
                  {(items || []).map((logo, index) => (
                    <div key={index} className={styles.logoSlide}>
                      {logo?.inline_svg ? (
                        <SVGLibrary name={logo?.inline_svg} />
                      ) : (
                        <PrismicNextImage height={40} field={logo?.logo} />
                      )}
                    </div>
                  ))}
                </Carousel>
              </div>
            </div>
          </>
        )}
      </section>
    );
  }

  if (variation === 'centered') {
    const {
      heading,
      description,
      primary_cta_text,
      primary_cta_url,
      primary_cta_target,
      background_color,
      text_color,
    } = primary;

    return (
      <section
        className={classNames('wide-container', styles.section, styles[background_color])}
        data-slice-type={slice_type}
        data-slice-variation={variation}
      >
        <div className={classNames(styles.centeredRoot)}>
          <div className={classNames(styles.content, styles.centeredContent)}>
            <h2 className={styles.header} data-testid="heading">
              {heading}
            </h2>
            <PrismicRichText
              className={classNames(styles.description, styles[text_color])}
              field={description}
              applyStyles={true}
              boldHighlight="primary"
            />
            {primary_cta_text && (
              <Button
                className={styles.btn}
                text={primary_cta_text}
                href={asLink(primary_cta_url)}
                target={primary_cta_target}
                dataTestid="cta-primary"
              />
            )}
          </div>
        </div>
      </section>
    );
  }

  if (variation === 'assets') {
    const { heading, sub_heading, text_color, background_color } = primary;

    return (
      <section
        className={classNames('wide-container', styles.assetsSection, styles[background_color])}
        data-slice-type={slice_type}
        data-slice-variation={variation}
      >
        <div className={styles.root}>
          <div className={styles.headerContainer}>
            <h1 className={classNames(styles.header, styles.textColor, styles[text_color])} data-testid="heading">
              {heading}
            </h1>
            {sub_heading && (
              <div className={classNames(styles.subHeader, styles.textColor, styles[text_color])}>{sub_heading}</div>
            )}
          </div>

          {(items || []).map((item, index) => {
            const {
              id,
              title,
              url,
              logo,
              dark_logo,
              cover,
              description,
              link_text = 'Read more',
              published_date,
            } = (item?.news as any)?.data || {};
            return (
              <div key={id} className={classNames(styles.assetContainer)}>
                <div className={styles.assetContent}>
                  {published_date && (
                    <div className={classNames(styles.eyebrow, styles.textColor, styles[text_color])}>
                      {published_date}
                    </div>
                  )}
                  <div>
                    {background_color === 'dark' ? (
                      <PrismicNextImage field={logo} height={24} />
                    ) : (
                      <PrismicNextImage field={dark_logo} height={24} />
                    )}
                  </div>
                  <div className={classNames(styles.title, styles.textColor, styles[text_color])}>{title}</div>
                  <div className={classNames(styles.description, styles.textColor, styles[text_color])}>
                    {description}
                  </div>
                  <div>
                    <Button
                      href={asLink(url)}
                      target={'_self'}
                      appearance={ButtonAppearance.InvisibleLink}
                      className={classNames(styles.readMoreLink, styles.textColor, styles[text_color])}
                    >
                      <div className={classNames(styles.readMoreContent)}>
                        <span>{link_text}</span>
                        <SVGLibrary name={'LinkForwardArrow'} fill={background_color === 'light' ? 'black' : 'white'} />
                      </div>
                    </Button>
                  </div>
                </div>
                <div className={styles.assetCover}>
                  <PrismicNextImage field={cover} />
                </div>
              </div>
            );
          })}
        </div>
      </section>
    );
  }

  if (variation === 'blog') {
    const { heading, text_color, image, background_color } = primary;
    const published_at = format(new Date((context as any).published_at), 'dd MMM yyyy') || {};
    const readingtime = (context as any).readingtime || {};

    const author = (context as any).author || {};
    const categories = (context as any).categories || [];
    const { name: authorName, image: authorImage } = author?.data || {};
    const router = useRouter();
    const { uid } = router?.query || {};

    const wordCount = heading?.trim()?.split(/\s+/)?.length;
    return (
      <section
        className={classNames('wide-container', styles.blogPostSection, styles[background_color])}
        data-slice-type={slice_type}
        data-slice-variation={variation}
      >
        <div className={styles.root}>
          <BreadCrumbs background_color={background_color} slug={uid} />
          <div className={classNames(styles.blogPostContainer)}>
            <div className={styles.column1} id="colum1-blog-article">
              <h1
                className={classNames(styles.blogHeadeing, styles.textColor, styles[text_color])}
                data-testid="heading"
              >
                {heading}
              </h1>
              {authorName && (
                <div className={classNames(styles.blogAutorSection, wordCount <= 6 && styles.authorAbsolute)}>
                  <div className={styles.blogAutor}>
                    {asImageSrc(authorImage) && !authorImage.url.includes(FIXED_AUTHOR_IMG_FILE_NAME) && (
                      <div className={styles.avatar}>
                        <PrismicNextImage field={authorImage} />
                      </div>
                    )}

                    <div className={styles.authorInfo}>
                      <div className={classNames(styles.authorIntro, styles.textColor, styles[text_color])}>
                        {authorName}
                      </div>
                      {published_at && (
                        <div className={classNames(styles.authorSecondaryText, styles.textColor, styles[text_color])}>
                          <span>
                            {published_at}
                            <span className={styles.bullet}>&bull;</span>
                            {readingtime} mins read
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={styles.blogTags}>
                    {categories &&
                      categories.map((category, index) => {
                        const { sub_heading } = category?.category?.data || {};
                        if (sub_heading) {
                          return (
                            <div className={classNames(styles.tag, styles[background_color])}>
                              <span className={classNames(styles.tagText, styles.textColor, styles[text_color])}>
                                {sub_heading}
                              </span>
                            </div>
                          );
                        }
                        return null;
                      })}
                  </div>
                </div>
              )}
            </div>
            <div className={styles.column2}>
              <div className={styles.blogCover}>
                <PrismicNextImage field={image} data-elastic-name="primary_image" />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }

  if (variation === 'featureCard') {
    const { pre_heading, heading, sub_heading, text_color, background_color, related_blogs } = primary;
    const pageRelatedBlogs = JSON.parse(related_blogs);
    const unifiedBlogs = normalizeBlogs(pageRelatedBlogs, items);
    return (
      <section
        className={classNames('wide-container', styles.featureCardSection, styles[background_color])}
        data-slice-type={slice_type}
        data-slice-variation={variation}
      >
        <div className={styles.root}>
          <div className={styles.headerContainer}>
            {pre_heading && (
              <div className={classNames(styles.tags)}>
                <span className={classNames(styles.tag, styles.preHeader, styles.textColor, styles[text_color])}>
                  {pre_heading}
                </span>
              </div>
            )}
            <h1 className={classNames(styles.header, styles.textColor, styles[text_color])} data-testid="heading">
              {heading}
            </h1>
            {sub_heading && (
              <div className={classNames(styles.subHeader, styles.textColor, styles[text_color])}>{sub_heading}</div>
            )}
          </div>
          <div className={classNames(styles.blogContainer)}>
            {unifiedBlogs[0] && (
              <Link key={index} href={unifiedBlogs[0]?.url || ''} unstyled>
                <div className={styles.column1}>
                  <div className={styles.coverImgWrapper}>
                    <div
                      className={styles.coverImg}
                      style={{
                        backgroundImage: `url(${unifiedBlogs[0]?.imageUrl || ''})`,
                      }}
                    ></div>
                  </div>
                  <>
                    {unifiedBlogs[0] && unifiedBlogs[0].tags && unifiedBlogs[0].tags.length > 0 && (
                      <div className={classNames(styles.tags)}>
                        {unifiedBlogs[0].tags.map((tag, index) => {
                          if (tag) {
                            return (
                              <span
                                key={index}
                                className={classNames(
                                  styles.tag,
                                  styles.preHeader,
                                  styles.textColor,
                                  styles[text_color],
                                )}
                              >
                                {tag?.tag || ''}
                              </span>
                            );
                          }
                          return null;
                        })}
                      </div>
                    )}
                    <div className={classNames(styles.blogHeading, styles.textColor, styles[text_color])}>
                      {unifiedBlogs[0]?.title || ''}
                    </div>
                    <Link
                      className={styles.readMoreLink}
                      endIcon={<ChevronRight fill={background_color === 'light' ? 'black' : 'white'} />}
                      type="emphasis"
                    >
                      {unifiedBlogs[0].ctaText || 'Read more'}
                    </Link>
                  </>
                  <div className={classNames(styles.divider, styles.firstDivider)} />
                </div>
              </Link>
            )}
            <div className={styles.column2}>
              {(unifiedBlogs || []).slice(1).map((item, index) => {
                const { title, ctaText, tags, url } = item;
                if (title) {
                  return (
                    <div key={title}>
                      <div className={styles.blogItem}>
                        {tags && tags.length > 0 && (
                          <div className={classNames(styles.tags)}>
                            {tags.map((tag, index) => {
                              if (tag) {
                                return (
                                  <span
                                    key={index}
                                    className={classNames(
                                      styles.tag,
                                      styles.preHeader,
                                      styles.textColor,
                                      styles[text_color],
                                    )}
                                  >
                                    {tag?.tag || ''}
                                  </span>
                                );
                              }
                              return null;
                            })}
                          </div>
                        )}
                        <Link key={index} href={url} unstyled>
                          <div className={classNames(styles.blogHeading, styles.textColor, styles[text_color])}>
                            {title || ''}
                          </div>
                          <Link
                            className={styles.readMoreLink}
                            endIcon={<ChevronRight fill={background_color === 'light' ? 'black' : 'white'} />}
                            type="emphasis"
                          >
                            {ctaText || 'Read more'}
                          </Link>
                        </Link>
                      </div>
                      {unifiedBlogs.length - 2 !== index && <div className={styles.divider} />}
                    </div>
                  );
                }
                return null;
              })}
            </div>
          </div>
        </div>
      </section>
    );
  }

  if (variation === 'resource') {
    const { heading, text_color, background_color, background_image, author, categories, published_date, read_time } =
      primary;
    const { name: authorName, image: authorImage } = (author as any)?.data || {};
    return (
      <section
        className={classNames('wide-container', styles.resourceSection, styles[background_color])}
        data-slice-type={slice_type}
        data-slice-variation={variation}
        style={{ backgroundImage: `url("${background_image.url}")` }}
      >
        <div className={styles.root}>
          <div className={classNames(styles.resourceContainer)}>
            <div className={styles.content}>
              <h1
                className={classNames(styles.resourceHeadeing, styles.textColor, styles[text_color])}
                data-testid="heading"
              >
                {heading}
              </h1>
              <div className={classNames(styles.resourceAutorSection)}>
                {authorName && (
                  <div className={styles.resourceAutor}>
                    <div className={styles.avatar}>
                      <PrismicNextImage field={authorImage} />
                    </div>
                    <div className={styles.authorInfo}>
                      <div className={classNames(styles.authorIntro, styles.textColor, styles[text_color])}>
                        {authorName}
                      </div>
                      {published_date && (
                        <div className={classNames(styles.authorSecondaryText, styles.textColor, styles[text_color])}>
                          <span>
                            {published_date} &bull; {read_time}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {!authorName && published_date && (
                  <div
                    className={classNames(
                      styles.authorSecondaryText,
                      styles.withoutAutor,
                      styles.textColor,
                      styles[text_color],
                    )}
                  >
                    <span>
                      {published_date} &bull; {read_time}
                    </span>
                  </div>
                )}
              </div>

              <div className={styles.resourcesTags}>
                {categories &&
                  categories.split(',').map((cat: string) => (
                    <div className={classNames(styles.tag, styles[background_color])}>
                      <span className={classNames(styles.tagText, styles.textColor, styles[text_color])}>{cat}</span>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }

  return <HeroLight slice={slice} index={index} slices={slices} context={context} />;
};

const BreadCrumbs = ({ background_color, slug }) => {
  const trimSlug = slug => {
    if (slug && slug.length > 15) {
      return slug || `${slug.substring(0, 15)} ...`;
    }
    return slug;
  };
  useEffect(() => {
    const handleBreadcrumbSize = () => {
      const col1 = document.getElementById('colum1-blog-article');
      const blink1 = document.getElementById('breadcrumb-link-1');
      const blink2 = document.getElementById('breadcrumb-link-2');
      document.getElementById('breadCrumbs-wrapper').style.width = `${col1.offsetWidth}px`;
      // Now assign the width to last element means slug
      const slug = document.getElementById('breadcrumbs-slug');
      slug.style.width = `${col1.offsetWidth - blink1.offsetWidth - blink2.offsetWidth - 38}px`;
    };
    handleBreadcrumbSize();
    window.addEventListener('resize', handleBreadcrumbSize);
    () => window.removeEventListener('resize', () => null);
  }, []);

  // const
  return (
    <div className={styles.breadCrumbs} id="breadCrumbs-wrapper">
      <a
        className={classNames(styles.link, styles[background_color])}
        href={'/'}
        target={'_self'}
        id="breadcrumb-link-1"
      >
        <SVGLibrary name={'Building'} fill={background_color === 'dark' ? 'white' : 'black'} />
        <span>Home</span>
      </a>
      <a
        className={classNames(styles.link, styles[background_color])}
        href={'/blog'}
        target={'_self'}
        id="breadcrumb-link-2"
      >
        <SVGLibrary name={'LinkForwardArrow'} fill={background_color === 'dark' ? 'white' : 'black'} />
        <span>Blog</span>
      </a>
      <div
        className={classNames(styles.link, styles.disabled, styles[background_color])}
        onClick={e => e.preventDefault()}
      >
        <SVGLibrary name={'LinkForwardArrow'} fill={background_color === 'dark' ? 'white' : 'black'} />
        <span id="breadcrumbs-slug">{trimSlug(slug)}</span>
      </div>
    </div>
  );
};

export default Hero;
